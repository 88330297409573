import React from 'react';
import Helmet from 'react-helmet';

import { BREAKPOINT } from '@latitude/core/utils/constants';
import Layout from '@/components/layout';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

import heroImage from '../images/hero-djcard1.webp';

function DavidJonesAchFormPage(props) {
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/david-jones/addcard/achform/"
          />
          <meta
            name="description"
            content="Add an additional cardholder to your David Jones credit card"
          />
          <title>Additional Card Holder | David Jones credit card</title>
        </Helmet>

        <div
          className="pageHeader"
          css={`
            && section.lfs-pageheader {
              background: rgb(0, 0, 0);
            }
            @media (min-width: ${BREAKPOINT.LG}) {
              && div.lfs-pageheader-hero > div {
                max-height: 400px;
              }
              && div.HeroContent {
                padding: 0 0;
              }
            }
          `}
        >
          <PageHeaderSection
            title="David Jones additional cardholder"
            subTitle="Help them experience a credit card like no other."
            pageImage={heroImage}
            pageImageAlt="David Jones cards"
          />
        </div>

        <div
          css={{ clear: 'both', margin: '10px 0' }}
          className="lfs-iframe-eform"
        >
          <iframe
            css={{
              width: '100%',
              minHeight: '800px',
              border: 'none',
              padding: '10px'
            }}
            title="Request an Additional Card Holder"
            src="https://eform.latitudefinancial.com.au/eform/Page.do?eFormName=ACH_davidjones"
          />
        </div>
      </main>
    </Layout>
  );
}

export default DavidJonesAchFormPage;
